import {createGlobalStyle, styled} from 'styled-components';
import Pixboy from  '../fonts/Pixeboy.ttf';



const Header = styled.div`
  background-color:#000;
  position:fixed;
  top:0;
  width:100%;
  z-index:11;
`;

const InternetMoneyLink = styled.a`
  font-family: Pixboy;
  text-decoration: none;  
  color: #fba81a;
  font-weight: normal;
  font-size: 2.5em;
`;

const DevLicenceClub = styled.a`
font-family: 'Courier New' ;
font-weight: bold;
text-decoration: none;  
color: #414aca;
font-weight: normal;
font-size: 4em;
`;

const BodySection = styled.div`
  padding-top:2.875em;
  padding-bottom: 0em;
  padding-right:0em;
`;

const Container = styled.div` 
  margin:0 auto;
  max-width:1480px;
  padding:0 1.5em;
  width:90%;
 `;

 const NavButton = styled.button `
  align-items: center;
  background-color: ${({ color }) => '#000' || color};
  border: .075em solid #737373;
  border-radius: 3.75em;
  color: ${({ color }) => color || '#fba81a'};
  display: flex;
  font-size: 1em;
  margin-left:.5em;
  font-weight: 700;
  justify-content: space-between;
  text-transform: uppercase;
  width: -webkit-max-content;
  width: max-content;
  line-height:inherit;
  padding:.5em 1.625em;
  &:hover {
    background-color: ${({ color }) => color || '#fba81a'};
    color: ${({ color }) => '#000' || color};
  }  
`;

const NavBar = styled.div`
display:flex;
margin-left:.5em;
`;

const Label = { 
  padding: "10px 20px", textAlign: "center" 
};

const FormContainer = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
};
const ErrorMessage = styled.p`
  color: #f00;
  font-size:0.8em;
  justifyContent: "center";
  alignItems: "center";
  display:block;
`;

const BannerTitle = styled.div`
  color: #fba81a;
  font-size:5.6875em;
  font-weight:700;
  line-height:1.3;
  text-align: left;
  text-transform: uppercase;
  @media (max-width: 768px) {
    font-size:2.6875em;
  }`;

const BannerSubTitle = styled.div`
  color: #fff;
  font-size:1.5em;
  margin-top:.5em;
  max-width:80%;
`;

const TimeFeeRow = styled.div`
    display:block;
    flex-direction:row;
    font-size:1em;
    margin:1em 0;
`;

const TimeFeeRowInfo = styled.img`
  width: 28px;
  height: 28px;
`;

const TimeFeeRowTitle = styled.div`
  text-align:left;
  text-transform:uppercase;
  font-weight:bold;
  color: #fff;
  flex-grow: 1;
  padding:1em;
`;

const TimeFeeRowEnds = styled.div`
  flex: 0.9;
  color: #fff;
  display:flex;
  align-items:left;
  flex-direction:row;
`;

const TimeFeeRowValue = styled.div`
  all:unset;
  flex-grow:1;
  text-overflow:ellipsis;
`;

const WalletAddressList = styled.div`
  max-height: 100px;
  overflow: auto;
`;

const WalletAddressListItem = styled.div`
  text-align:left;
  font-weight:bold;
  color: #fff;
  flex-grow: 1;
  padding:2px;
`;

const InformationText = styled.p`
  color: #08f;
`;

const GeneralParagragh = styled.p`
  color:#fff;
`;

const DropDownContainer = styled.div`
  width: 10.5em;
  margin: 0 auto;
`;

const DropDownHeader = styled.div`
  margin-bottom: 0.8em;
  padding: 0.4em 2em 0.4em 1em;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  font-weight: 500;
  font-size: 1.3rem;
  color: #3faffa;
`;

const DropDownListContainer = styled.div`
  position: absolute;
  z-index: 100;
  width: 10.5em;
`;

const DropDownList = styled.ul`
  padding: 0;
  margin: 0;
  padding-left: 1em;
  background: #ffffff;
  border: 2px solid #e5e5e5;
  box-sizing: border-box;
  color: #3faffa;
  font-size: 1.3rem;
  font-weight: 500;
  &:first-child {
    padding-top: 0.8em;
  }
`;

const ListItem = styled.li`
  list-style: none;
  margin-bottom: 0.8em;
  &:hover {
    color: #fd9e46;
  }
`;

const SendMessageButton = styled.button`
  /* Define your styles here */
  padding: 10px 20px;
  background-color: ${({ color }) => color || 'blue'};
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

export const styles = {
  Container: Container,
  Header: Header,
  BodySection: BodySection,
  Label: Label,
  ErrorMessage: ErrorMessage,
  FormContainer: FormContainer,
  BannerTitle: BannerTitle,
  BannerSubTitle: BannerSubTitle,
  InternetMoneyLink: InternetMoneyLink,
  TimeFeeRow,
  TimeFeeRowInfo: TimeFeeRowInfo,
  TimeFeeRowTitle: TimeFeeRowTitle,
  TimeFeeRowEnds: TimeFeeRowEnds,
  TimeFeeRowValue: TimeFeeRowValue,
  InformationText: InformationText,
  NavButton: NavButton,
  NavBar: NavBar,
  GeneralParagragh: GeneralParagragh,
  WalletAddressList: WalletAddressList,
  WalletAddressListItem: WalletAddressListItem,
  DropDownContainer: DropDownContainer,
  DropDownHeader: DropDownHeader,
  DropDownListContainer: DropDownListContainer,
  DropDownList: DropDownList,
  ListItem: ListItem,
  DevLicenceClub: DevLicenceClub,
  SendMessageButton: SendMessageButton
}

export default createGlobalStyle`
  @font-face {
    font-family: Pixboy;
    src: url(${Pixboy}); 
  }
`;